<template>
    <div>
        <!--Delete Profile-->
        <b-modal no-close-on-backdrop scrollable title="Remove Employee" class="modal-dark" v-model="deleteProfileModal" >
            <p>ARE YOU SURE YOU WANT TO REMOVE THIS {{tempStaff.position}}?</p>
            <template slot="modal-footer">
                <b-button @click="deleteProfileModal = false" size="sm" variant="secondary">Cancel</b-button>
                <b-button @click="deleteProfile()" size="sm" variant="danger">Delete</b-button>
            </template>
        </b-modal>
        <!--Add Profile-->
        <b-modal no-close-on-backdrop scrollable title="Add Employee" class="modal-dark" v-model="addProfileModal">
            <b-row>
                <b-col sm="6">
                    <b-form-group>
                        <label>First Name</label>
                        <b-form-input v-model="tempStaff.firstName" v-uppercase/>
                    </b-form-group>
                </b-col>
                <b-col sm="6">
                    <b-form-group>
                        <label>Last Name</label>
                        <b-form-input v-model="tempStaff.lastName" v-uppercase/>
                    </b-form-group>
                </b-col>
                <b-col sm="6">
                    <label>Start Date</label>
                    <b-form-group>
                        <b-form-input v-model="tempStaff.startDate" type="date" id="date"/>
                    </b-form-group>
                </b-col>
                <b-col sm="6">
                    <b-form-group>
                        <label>Status</label>
                        <b-form-input v-model="tempStaff.status" v-uppercase/>
                    </b-form-group>
                </b-col>
                <b-col sm="9">
                    <b-form-group>
                        <label>Position</label><br>
                        <b-form-select :options="positions" v-model="tempStaff.position">
                        </b-form-select>
                    </b-form-group>
                </b-col>
                <b-col sm="3">
                    <b-form-group>
                        <label>Badge #</label>
                        <b-form-input v-model="tempStaff.starNumber" type="number" :formatter="formatBadge"/>
                    </b-form-group>
                </b-col>
            </b-row>
            <template slot="modal-footer">
                <b-button size="sm" variant="secondary" @click="addProfileModal = false">Cancel</b-button>
                <b-button size="sm" variant="primary" :disabled="fillForm()" @click="postProfile">
                    Submit
                </b-button>
            </template>
        </b-modal>
        <!--Edit Profile-->
        <b-modal no-close-on-backdrop scrollable title="Edit Employee" class="modal-dark" v-model="editProfileModal">
            <b-row>
                <b-col sm="6">
                    <b-form-group>
                        <label>First Name</label>
                        <b-form-input v-model="tempStaff.firstName" v-uppercase/>
                    </b-form-group>
                </b-col>
                <b-col sm="6">
                    <b-form-group>
                        <label>Last Name</label>
                        <b-form-input v-model="tempStaff.lastName" v-uppercase/>
                    </b-form-group>
                </b-col>
                <b-col sm="6">
                    <b-form-group>
                        <label>Start Date</label>
                        <b-form-input v-model="tempStaff.startDate" type="date" id="date"/>
                    </b-form-group>
                </b-col>
                <b-col sm="6">
                    <b-form-group>
                        <label>Status</label>
                        <b-form-input v-model="tempStaff.status" v-uppercase/>
                    </b-form-group>
                </b-col>
                <b-col sm="9">
                    <b-form-group>
                        <label>Position</label><br>
                        <b-form-select :options="positions" v-model="tempStaff.position">
                        </b-form-select>
                    </b-form-group>
                </b-col>
                <b-col sm="3">
                    <b-form-group>
                        <label>Badge #</label>
                        <b-form-input v-model="tempStaff.starNumber" type="number" :formatter="formatBadge"/>
                    </b-form-group>
                </b-col>
            </b-row>
            <template slot="modal-footer">
                <b-button @click="openDeleteModal(); disableDeleteFlag = false;"
                          size="sm" style="float: left" variant="danger">Delete
                </b-button>
                <b-button size="sm" variant="secondary" @click="editProfileModal = false">Cancel</b-button>
                <b-button size="sm" variant="primary" :disabled="fillForm()" @click="putProfile">
                    Submit
                </b-button>
            </template>
        </b-modal>

        <!--Main Body-->
        <b-card>
            <div slot="header">
                <div class="d-flex w-100 justify-content-between">
                    <h5 style="margin-bottom: 0"><b>Employee Manager</b></h5>
                    <div class="d-flex justify-content-end">
                        <i class="fa fa-user-plus fa-lg" @click="clearProfileFields(); addProfileModal = true"/>
                    </div>
                </div>
            </div>
            <v-client-table ref="dTable" style="width: 100%" :columns="[...columns, 'edit']" :data="staff"
                            :options="{...tOptions, sortable: columns, filterable: columns}" :theme="theme"
                            class="dataTable">
                <div slot="edit" slot-scope="props" >
                    <i class="fa fa-edit fa-lg" style="display: flex; justify-content: right" @click="editProfile(props.row)"/>
                </div>
                <div slot="h__edit" style="float: right">
                    <span style="float: right; margin-bottom: 0">Edit</span>
                </div>
            </v-client-table>
        </b-card>
    </div>
</template>

<script>
import {ClientTable} from 'vue-tables-2'
import Vue from "vue";
Vue.use(ClientTable)

export default {
    name: "sheriffStaff",
    data() {
        return {
            staff: [
                {starNumber: 3917, lastName: 'ABATE', firstName: 'JAMES', position: 'DETECTIVE', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 3375, lastName: 'ADKINS', firstName: 'ALLAN', position: 'DEPUTY SHERIFF', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 8769, lastName: 'AIRDO', firstName: 'LAWRENCE', position: 'CORRECTIONAL OFFICER', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 1645, lastName: 'ALLEN', firstName: 'ANDRE', position: 'CORRECTIONAL OFFICER', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 6529, lastName: 'ALLTON', firstName: 'GREGORY', position: 'RVE DPTY- NO IMRF', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 7082, lastName: 'ALLTON', firstName: 'DOUGLAS', position: 'RVE DPTY- NO IMRF', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 4817, lastName: 'ANDERSON', firstName: 'DOUGLAS', position: 'CORRECTIONAL OFFICER', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 6474, lastName: 'APEL', firstName: 'DANA', position: 'CORRECTIONAL OFFICER', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 8218, lastName: 'ARBISI', firstName: 'GIORGI', position: 'DEPUTY SHERIFF', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 6368, lastName: 'ASPROOTH', firstName: 'NICHOLAS', position: 'CORRECTIONS SERGEANT', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 8238, lastName: 'ATCHLEY', firstName: 'TYLER', position: 'CORRECTIONAL OFFICER', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 6644, lastName: 'ATWOOD', firstName: 'WILLIAM', position: 'CORRECTIONAL OFFICER', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 1257, lastName: 'AUSTIN', firstName: 'KENNETH', position: 'DEPUTY SHERIFF', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 4847, lastName: 'BAATZ', firstName: 'CHRISTOPHE', position: 'CORRECTIONAL OFFICER', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 3958, lastName: 'BALLINGER', firstName: 'LISA', position: 'DETECTIVE', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 1865, lastName: 'BARCELLONA', firstName: 'DOMINICK', position: 'DEPUTY CHIEF', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 5421, lastName: 'BARCELLONA', firstName: 'REBECCA', position: 'PT WITH BENEFITS', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 7323, lastName: 'BARDZINSKA', firstName: 'BASIA', position: 'DEPUTY SHERIFF', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 5384, lastName: 'BAYLOR', firstName: 'ANDREW', position: 'DEPUTY SHERIFF', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 8672, lastName: 'BECK', firstName: 'TYLER', position: 'CORRECTIONAL OFFICER', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 2720, lastName: 'BEITEL', firstName: 'ERIC', position: 'CORRECTIONAL OFFICER', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 7219, lastName: 'BEITEL', firstName: 'CHAD', position: 'RVE DPTY- NO IMRF', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 7307, lastName: 'BENAVIDES', firstName: 'JAMIE', position: 'DEPUTY SHERIFF', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 9152, lastName: 'BERES', firstName: 'RONALD', position: 'CORRECTIONAL OFFICER', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 8920, lastName: 'BERRY', firstName: 'SAMUEL', position: 'CORRECTIONAL OFFICER', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 8803, lastName: 'BISHOP', firstName: 'TIMOTHY', position: 'CORRECTIONAL OFFICER', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 4539, lastName: 'BONS', firstName: 'JILL', position: 'CORRECTIONAL OFFICER', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 1768, lastName: 'BOOMER', firstName: 'JOSEPH', position: 'LIEUTENANT', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 4119, lastName: 'BOOTH', firstName: 'SCOTT', position: 'CORRECTIONAL OFFICER', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 3909, lastName: 'BORDEN', firstName: 'PHILLIP', position: 'CORRECTIONAL OFFICER', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 4063, lastName: 'BOSCHMA', firstName: 'DENNIS', position: 'CORRECTIONAL OFFICER', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 1659, lastName: 'BOUNDS', firstName: 'CHAD', position: 'CORRECTIONS SERGEANT', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 2597, lastName: 'BOYD', firstName: 'DANIEL', position: 'CORRECTIONS LIEUTENANT', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 3426, lastName: 'BOYD', firstName: 'JAIME', position: 'CORRECTIONAL OFFICER', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 7763, lastName: 'BRADFORD', firstName: 'RODNEY', position: 'DEPUTY SHERIFF', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 8477, lastName: 'BREWER', firstName: 'GAGE', position: 'CORRECTIONAL OFFICER', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 2963, lastName: 'BRIMHALL', firstName: 'THOMAS', position: 'SERGEANT', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 4866, lastName: 'BROULLARD', firstName: 'JOSEPH', position: 'SERGEANT', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 7141, lastName: 'BROWN', firstName: 'KENNETH', position: 'CORRECTIONAL OFFICER', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 8744, lastName: 'BRYAN', firstName: 'TYLER', position: 'DEPUTY SHERIFF', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 1283, lastName: 'BUCHANAN', firstName: 'MICHELLE', position: 'CORRECTIONAL OFFICER', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 5243, lastName: 'BUETSCH', firstName: 'BRIAN', position: 'CORRECTIONAL OFFICER', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 8449, lastName: 'CALLAHAN', firstName: 'JOSEPH', position: 'CORRECTIONAL OFFICER', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 8697, lastName: 'CARAVELLO', firstName: 'JOSEPH', position: 'DEPUTY SHERIFF', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 8220, lastName: 'CARLSEN', firstName: 'JOEL', position: 'DEPUTY SHERIFF', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 2024, lastName: 'CARLSON', firstName: 'SCOTT', position: 'RVE DPTY- NO IMRF', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 1657, lastName: 'CARTER', firstName: 'RYAN', position: 'DEPUTY SHERIFF', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 7006, lastName: 'CARUANA', firstName: 'GARY', position: 'COUNTY SHERIFF', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 8180, lastName: 'CASTANEDA', firstName: 'EDGAR', position: 'CORRECTIONAL OFFICER', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 2874, lastName: 'CATALANOTTO', firstName: 'ONOFRIO', position: 'DEPUTY SHERIFF', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 7548, lastName: 'CHATLOSH', firstName: 'MARK', position: 'CORRECTIONAL OFFICER', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 5320, lastName: 'CHAVEZ', firstName: 'CESAR', position: 'DEPUTY SHERIFF', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 8604, lastName: 'CIGANEK', firstName: 'RICHARD', position: 'CHIEF DEPUTY', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 7262, lastName: 'CISNEROS', firstName: 'JEREMY', position: 'CORRECTIONAL OFFICER', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 8617, lastName: 'CISNEROS', firstName: 'BRANDON', position: 'CORRECTIONAL OFFICER', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 7309, lastName: 'CLARK', firstName: 'CHRISTOPHER', position: 'CORRECTIONAL OFFICER', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 7180, lastName: 'CONLEY', firstName: 'MATTHEW', position: 'DEPUTY SHERIFF', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 8804, lastName: 'CONTRERAS', firstName: 'MARCOS', position: 'DEPUTY SHERIFF', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 3854, lastName: 'COOK', firstName: 'BILLIE JEAN', position: 'CORRECTIONAL OFFICER', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 3633, lastName: 'COOMER', firstName: 'TIMOTHY', position: 'DEPUTY SHERIFF', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 8921, lastName: 'COWGILL', firstName: 'ZACHARI', position: 'CORRECTIONAL OFFICER', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 8673, lastName: 'COWMAN', firstName: 'DANIEL', position: 'CORRECTIONAL OFFICER', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 3932, lastName: 'COX', firstName: 'TIMOTHY', position: 'CORRECTIONAL OFFICER', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 7310, lastName: 'CURTIS', firstName: 'MICHAEL', position: 'CORRECTIONAL OFFICER', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 8745, lastName: 'DAILING', firstName: 'JACOB', position: 'CORRECTIONAL OFFICER', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 1776, lastName: 'DALPRA', firstName: 'PETER', position: 'DEPUTY CHIEF', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 3048, lastName: 'DAVIDSON', firstName: 'KURT', position: 'CORRECTIONAL OFFICER', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 2400, lastName: 'DAVIS', firstName: 'CHRISTOPHER', position: 'CORRECTIONAL OFFICER', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 4234, lastName: 'DELGADO', firstName: 'MICHAEL', position: 'CORRECTIONAL OFFICER', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 8618, lastName: 'DELRE', firstName: 'JONATHAN', position: 'CORRECTIONAL OFFICER', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 8922, lastName: 'DENNIS', firstName: 'DAKOTA', position: 'CORRECTIONAL OFFICER', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 6655, lastName: 'DEVORE', firstName: 'CHAD', position: 'DEPUTY SHERIFF', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 5367, lastName: 'DITTO', firstName: 'MATTHEW', position: 'CORRECTIONAL OFFICER', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 7662, lastName: 'DOCK', firstName: 'BARRY', position: 'RVE DPTY- NO IMRF', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 2025, lastName: 'DODD', firstName: 'JULIE', position: 'EVIDENCE OFFICER', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 4255, lastName: 'DONALDSON', firstName: 'ALESSANDRO', position: 'CORRECTIONAL OFFICER', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 9123, lastName: 'DOYLE', firstName: 'SAMANTHA', position: 'CORRECTIONAL OFFICER', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 1520, lastName: 'DREHER', firstName: 'GREGORY', position: 'CORRECTIONAL OFFICER', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 4899, lastName: 'DRYE', firstName: 'CATHERINE', position: 'CORRECTIONAL OFFICER', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 8264, lastName: 'EDMONDSON', firstName: 'STEPHANIE', position: 'CORRECTIONAL OFFICER', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 4408, lastName: 'EGERERE', firstName: 'JOHN', position: 'CORRECTIONAL OFFICER', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 1974, lastName: 'EGLER', firstName: 'JUSTIN', position: 'CORRECTIONS LIEUTENANT', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 6318, lastName: 'EISMAN', firstName: 'TIFFANY', position: 'DEPUTY SHERIFF', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 6755, lastName: 'EITEN', firstName: 'GABRIEL', position: 'DEPUTY SHERIFF', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 4062, lastName: 'ELLIS', firstName: 'DARCIE', position: 'DEPUTY SHERIFF', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 7765, lastName: 'ELYAS', firstName: 'ABDELMONEM', position: 'CORRECTIONAL OFFICER', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 1596, lastName: 'ENNA', firstName: 'ANTHONY', position: 'CORRECTIONAL OFFICER', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 5561, lastName: 'FAGAN', firstName: 'PAULA', position: 'CORRECTIONAL OFFICER', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 5240, lastName: 'FERGUSON', firstName: 'BRUCE', position: 'CORRECTIONAL OFFICER', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 3928, lastName: 'FERNANDES', firstName: 'ASHLYN', position: 'CORRECTIONS LIEUTENANT', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 1717, lastName: 'FERRO', firstName: 'VITO', position: 'RVE DPTY- NO IMRF', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 4406, lastName: 'FERRUZZA', firstName: 'DANIEL', position: 'DEPUTY SHERIFF', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 6948, lastName: 'FILLERS', firstName: 'WILLIAM', position: 'DETECTIVE', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 3750, lastName: 'FISH', firstName: 'RYAN', position: 'DEPUTY SHERIFF', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 2807, lastName: 'FISHER', firstName: 'MICHAEL', position: 'CORRECTIONS SERGEANT', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 2081, lastName: 'FOOTE', firstName: 'BRENDA', position: 'CORRECTIONAL OFFICER', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 1653, lastName: 'FRANCK', firstName: 'BRYCE', position: 'CORRECTIONS SERGEANT', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 2401, lastName: 'FREEDLUND', firstName: 'DANIEL', position: 'DEPUTY SHERIFF', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 1020, lastName: 'FRENCH', firstName: 'JOHN', position: 'RVE DPTY- NO IMRF', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 9019, lastName: 'GAMBINO', firstName: 'MICHAEL', position: 'DEPUTY SHERIFF', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 2867, lastName: 'GANZ', firstName: 'WESLEY', position: 'DEPUTY SHERIFF', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 8550, lastName: 'GASPARINI', firstName: 'NICHOLAS', position: 'DEPUTY SHERIFF', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 3607, lastName: 'GAZIANO', firstName: 'LEONARD', position: 'CORRECTIONAL OFFICER', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 5321, lastName: 'GESNER', firstName: 'JOSHUA', position: 'SERGEANT', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 6626, lastName: 'GLOVER', firstName: 'JUAN', position: 'DEPUTY SHERIFF', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 6806, lastName: 'GOMEZ RAMIREZ', firstName: 'CARLOS', position: 'DEPUTY SHERIFF', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 8766, lastName: 'GOMEZ ZUNO', firstName: 'CESAR', position: 'CORRECTIONAL OFFICER', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 2090, lastName: 'GORAL', firstName: 'TAMI', position: 'EXEC ASSISTANT TO THE SHERIFF', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 3908, lastName: 'GORMAN', firstName: 'DANIELLE', position: 'DEPUTY SHERIFF', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 5164, lastName: 'GRAIKA', firstName: 'RYAN', position: 'CORRECTIONAL OFFICER', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 5274, lastName: 'GRASLEY', firstName: 'CHARLES', position: 'DETECTIVE', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 4842, lastName: 'GRIESMAN', firstName: 'TIMOTHY', position: 'DEPUTY SHERIFF', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 7087, lastName: 'GRUNINGER', firstName: 'MICHAEL', position: 'DEPUTY SHERIFF', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 8883, lastName: 'HAFFENDEN', firstName: 'ALEXANDER', position: 'CORRECTIONAL OFFICER', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 4897, lastName: 'HALL', firstName: 'JEFFRY', position: 'CORRECTIONAL OFFICER', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 5242, lastName: 'HARRIS', firstName: 'JOYCE', position: 'CORRECTIONAL OFFICER', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 2117, lastName: 'HARRISON', firstName: 'BRIAN', position: 'RVE DPTY- NO IMRF', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 4169, lastName: 'HASTINGS', firstName: 'PATRICK', position: 'DEPUTY SHERIFF', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 5365, lastName: 'HAUSER', firstName: 'ROBERT', position: 'CORRECTIONAL OFFICER', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 4190, lastName: 'HAWKS', firstName: 'PHILLIP', position: 'CORRECTIONAL OFFICER', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 1433, lastName: 'HAYES', firstName: 'RODNEY', position: 'CORRECTIONAL OFFICER', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 8471, lastName: 'HAYNES', firstName: 'ROBERT', position: 'RVE DPTY- NO IMRF', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 2268, lastName: 'HEAVIN', firstName: 'RYAN', position: 'SERGEANT', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 6185, lastName: 'HEINZEROTH', firstName: 'KYLE', position: 'CORRECTIONAL OFFICER', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 6756, lastName: 'HENERT', firstName: 'COLE', position: 'DETECTIVE', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 2735, lastName: 'HERNANDEZ', firstName: 'KIMBERLY', position: 'CORRECTIONS SERGEANT', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 1181, lastName: 'HILTON', firstName: 'PERCY', position: 'CORRECTIONAL OFFICER', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 6319, lastName: 'HINES', firstName: 'MATTHEW', position: 'DEPUTY SHERIFF', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 6976, lastName: 'HITTERMAN', firstName: 'NICKOLAS', position: 'CORRECTIONAL OFFICER', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 5319, lastName: 'HOFFMAN', firstName: 'DANIEL', position: 'DEPUTY SHERIFF', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 8924, lastName: 'HOLMAN', firstName: 'MARCUS', position: 'CORRECTIONAL OFFICER', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 6651, lastName: 'HOOKS', firstName: 'DAVID', position: 'RVE DPTY- NO IMRF', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 6313, lastName: 'HORN', firstName: 'ADRIENNE', position: 'DEPUTY SHERIFF', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 8884, lastName: 'HORTON', firstName: 'MCKENZIE', position: 'CORRECTIONAL OFFICER', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 4172, lastName: 'HUGHES', firstName: 'SEAN', position: 'SERGEANT', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 6770, lastName: 'HUGHES', firstName: 'ALEX', position: 'CORRECTIONAL OFFICER', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 1714, lastName: 'HUMPHRIES', firstName: 'ROBERT', position: 'RVE DPTY- NO IMRF', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 6261, lastName: 'HUMPHRIES', firstName: 'JONATHAN', position: 'DEPUTY SHERIFF', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 3823, lastName: 'HUSTON', firstName: 'MARK', position: 'CORRECTIONAL OFFICER', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 7120, lastName: 'INGARDONA', firstName: 'FRANK', position: 'ADMINISTRATIVE ASSISTANT', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 8917, lastName: 'INGRASSIA', firstName: 'THEODORE', position: 'DEPUTY SHERIFF', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 9118, lastName: 'IRWIN', firstName: 'JOAB', position: 'CORRECTIONAL OFFICER', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 8885, lastName: 'IVENS', firstName: 'BRIAN', position: 'CORRECTIONAL OFFICER', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 1741, lastName: 'JACOBSON', firstName: 'ROBERT', position: 'CORRECTIONS SERGEANT', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 2541, lastName: 'JAMES', firstName: 'WILLIAM', position: 'DEPUTY SHERIFF', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 3844, lastName: 'JARRETT', firstName: 'RICHARD', position: 'CORRECTIONAL OFFICER', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 3723, lastName: 'JARVIS', firstName: 'MATTHEW', position: 'DEPUTY SHERIFF', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 8732, lastName: 'JENKINS', firstName: 'KEVYN', position: 'DEPUTY SHERIFF', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 3491, lastName: 'JOHNSON', firstName: 'TERRANCE', position: 'CORRECTIONS SERGEANT', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 3975, lastName: 'JOHNSON', firstName: 'BRYAN', position: 'CORRECTIONAL OFFICER', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 8508, lastName: 'JOHNSON', firstName: 'BRIAN', position: 'CORRECTIONAL OFFICER', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 8674, lastName: 'JOHNSON', firstName: 'ERIC', position: 'CORRECTIONAL OFFICER', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 6682, lastName: 'JOHNSTON', firstName: 'ZACHARY', position: 'CORRECTIONAL OFFICER', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 3492, lastName: 'JONES', firstName: 'FRED', position: 'DETECTIVE', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 8982, lastName: 'JONES', firstName: 'HUNTER', position: 'DEPUTY SHERIFF', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 8536, lastName: 'JOSEPH', firstName: 'TIMOTHY', position: 'RVE DPTY- NO IMRF', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 3955, lastName: 'KAISER', firstName: 'BRADLEY', position: 'DETECTIVE', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 1694, lastName: 'KARNER', firstName: 'MARK', position: 'DEPUTY CHIEF', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 6338, lastName: 'KASZCUK', firstName: 'AARON', position: 'CORRECTIONAL OFFICER', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 6522, lastName: 'KELLETT', firstName: 'CLINTON', position: 'RVE DPTY- NO IMRF', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 1325, lastName: 'KEMP', firstName: 'WESTLEY', position: 'DEPUTY SHERIFF', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 3848, lastName: 'KEMPIN', firstName: 'STEVEN', position: 'DEPUTY SHERIFF', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 1973, lastName: 'KENNAY', firstName: 'JAMES', position: 'CORRECTIONS SERGEANT', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 7583, lastName: 'KENNAY', firstName: 'MARINA', position: 'DEPUTY SHERIFF', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 7384, lastName: 'KIEFER', firstName: 'PAUL', position: 'CORRECTIONAL OFFICER', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 2592, lastName: 'KINGDON', firstName: 'YVETTEIA', position: 'CORRECTIONAL OFFICER', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 4946, lastName: 'KIRBY', firstName: 'RUSSELL', position: 'CORRECTIONS SERGEANT', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 8983, lastName: 'KIRBY', firstName: 'MATTHEW', position: 'CORRECTIONAL OFFICER', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 8269, lastName: 'KLING', firstName: 'KYLE', position: 'CORRECTIONAL OFFICER', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 3073, lastName: 'KLUS', firstName: 'BRIAN', position: 'CORRECTIONAL OFFICER', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 4693, lastName: 'KNIFFEN', firstName: 'DAVID', position: 'CORRECTIONAL OFFICER', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 2176, lastName: 'KNUTSON', firstName: 'PAUL', position: 'CORRECTIONS LIEUTENANT', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 5238, lastName: 'KOHOUT', firstName: 'MICHELE', position: 'CORRECTIONAL OFFICER', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 4237, lastName: 'KOSINSKI', firstName: 'MARY', position: 'CORRECTIONAL OFFICER', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 8747, lastName: 'KRAPF', firstName: 'PAUL', position: 'CORRECTIONAL OFFICER', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 8181, lastName: 'KRYDER', firstName: 'JARED', position: 'DEPUTY SHERIFF', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 7215, lastName: 'KUNKLE', firstName: 'DANIEL', position: 'CORRECTIONAL OFFICER', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 4475, lastName: 'LALOWSKI', firstName: 'CHRISTOPHE', position: 'CORRECTIONAL OFFICER', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 8703, lastName: 'LAPLANCHE', firstName: 'RALPH', position: 'CORRECTIONAL OFFICER', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 6683, lastName: 'LAPP', firstName: 'SHAWN', position: 'DEPUTY SHERIFF', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 3685, lastName: 'LARSON', firstName: 'TODD', position: 'CORRECTIONAL OFFICER', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 9075, lastName: 'LAWHORN', firstName: 'SKYLAR', position: 'CORRECTIONAL OFFICER', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 9089, lastName: 'LEDESMA', firstName: 'DEREK', position: 'DEPUTY SHERIFF', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 8273, lastName: 'LEE', firstName: 'NICOLE', position: 'DEPUTY SHERIFF', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 1269, lastName: 'LEOMBRUNI', firstName: 'GREGORY', position: 'RVE DPTY- NO IMRF', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 7852, lastName: 'LEWIS', firstName: 'CHRISTOPHE', position: 'CORRECTIONAL OFFICER', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 3846, lastName: 'LICHTFUSS', firstName: 'JEFFERY', position: 'DEPUTY SHERIFF', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 3659, lastName: 'LINGBECK', firstName: 'DENNIS', position: 'DEPUTY SHERIFF', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 7653, lastName: 'LISNEY', firstName: 'ALLISON', position: 'CORRECTIONAL OFFICER', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 3916, lastName: 'LISTON', firstName: 'JOSHUA', position: 'CORRECTIONAL OFFICER', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 1947, lastName: 'LOLLI', firstName: 'MARK', position: 'CORRECTIONS LIEUTENANT', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 8715, lastName: 'LOVE', firstName: 'ANTHONY', position: 'CORRECTIONAL OFFICER', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 4950, lastName: 'LUKOWSKI', firstName: 'ROBERT', position: 'CORRECTIONS CAPTAIN', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 3824, lastName: 'LYNCH', firstName: 'MICHELLE', position: 'CORRECTIONAL OFFICER', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 1216, lastName: 'LYONS', firstName: 'MICHAEL', position: 'CORRECTIONAL OFFICER', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 6320, lastName: 'MAFFIOLI', firstName: 'MARK', position: 'DEPUTY SHERIFF', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 6558, lastName: 'MAGNUSON', firstName: 'GARY', position: 'RVE DPTY- NO IMRF', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 7552, lastName: 'MALGET', firstName: 'ELIZABETH', position: 'CORRECTIONAL OFFICER', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 3915, lastName: 'MARCHINI', firstName: 'MICHAEL', position: 'CORRECTIONAL OFFICER', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 5107, lastName: 'MARINO', firstName: 'JACOB', position: 'DEPUTY SHERIFF', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 4193, lastName: 'MARTINEZ', firstName: 'ESTEBAN', position: 'RVE DPTY- NO IMRF', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 5318, lastName: 'MAVILLE', firstName: 'JEFFREY', position: 'DEPUTY SHERIFF', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 5329, lastName: 'MCCORKLE', firstName: 'STEVE', position: 'CORRECTIONS SERGEANT', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 3976, lastName: 'MCCOY', firstName: 'KAI', position: 'CORRECTIONAL OFFICER', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 4945, lastName: 'MCCULLOCH', firstName: 'ANDREW', position: 'DEPUTY SHERIFF', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 9155, lastName: 'MCKINNEY', firstName: 'KATHERINE', position: 'CORRECTIONAL OFFICER', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 6977, lastName: 'MCKNIGHT', firstName: 'KATHERINE', position: 'CORRECTIONAL OFFICER', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 9156, lastName: 'MCNALLY', firstName: 'ANNA', position: 'CORRECTIONAL OFFICER', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 6933, lastName: 'MCWILLIAMS', firstName: 'TIMOTHY', position: 'CORRECTIONAL OFFICER', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 7192, lastName: 'MENDEZ', firstName: 'CESAR', position: 'CORRECTIONAL OFFICER', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 3495, lastName: 'MERRYMAN', firstName: 'BRADLEY', position: 'CORRECTIONAL OFFICER', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 4826, lastName: 'METZLER', firstName: 'STANLEY', position: 'DEPUTY SHERIFF', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 1766, lastName: 'MICELI', firstName: 'ANTHONY', position: 'DEPUTY CHIEF', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 5190, lastName: 'MIELKE', firstName: 'BRIAN', position: 'CORRECTIONAL OFFICER', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 1190, lastName: 'MILLER', firstName: 'BRENT', position: 'CORRECTIONAL OFFICER', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 8408, lastName: 'MILLER', firstName: 'AMARA', position: 'CORRECTIONAL OFFICER', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 6326, lastName: 'MILNER', firstName: 'ERIC', position: 'CORRECTIONS SERGEANT', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 8748, lastName: 'MONTOYA', firstName: 'MANUEL', position: 'CORRECTIONAL OFFICER', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 5439, lastName: 'MORRIS', firstName: 'BRIAN', position: 'CORRECTIONAL OFFICER', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 5315, lastName: 'MORRISON', firstName: 'THOMAS', position: 'DEPUTY SHERIFF', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 1446, lastName: 'MOSKI', firstName: 'CHRISTOPHER', position: 'DETECTIVE', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 4168, lastName: 'MOYER', firstName: 'JENNIFER', position: 'SERGEANT', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 5299, lastName: 'MUNOZ', firstName: 'LUIS', position: 'CORRECTIONAL OFFICER', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 4319, lastName: 'MUTO', firstName: 'SHANE', position: 'CORRECTIONAL OFFICER', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 6007, lastName: 'MUTO', firstName: 'NICHOLE', position: 'CORRECTIONAL OFFICER', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 6998, lastName: 'MUTO', firstName: 'ANDREW', position: 'CORRECTIONAL OFFICER', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 8410, lastName: 'NELSON', firstName: 'MARK', position: 'CORRECTIONAL OFFICER', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 8421, lastName: 'NESMITH', firstName: 'ZACHARY', position: 'DEPUTY SHERIFF', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 7057, lastName: 'NEWTON', firstName: 'KEVIN', position: 'RVE DPTY- NO IMRF', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 5479, lastName: 'NIEDERMEIER', firstName: 'JOSEPH', position: 'CORRECTIONAL OFFICER', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 2363, lastName: 'NOMANI', firstName: 'ILYAS', position: 'CORRECTIONAL OFFICER', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 6707, lastName: 'NORTH', firstName: 'ERIC', position: 'CORRECTIONAL OFFICER', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 8472, lastName: 'NYTKO', firstName: 'MARK', position: 'RVE DPTY- NO IMRF', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 4881, lastName: 'OLIVENCIA', firstName: 'JOSE', position: 'RVE DPTY- NO IMRF', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 9020, lastName: 'ORTEGA', firstName: 'LUIS', position: 'DEPUTY SHERIFF', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 4537, lastName: 'OSTERGARD', firstName: 'TRENTON', position: 'DEPUTY SHERIFF', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 1589, lastName: 'OWENS', firstName: 'TIMOTHY', position: 'CORRECTIONS CAPTAIN', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 7842, lastName: 'PARADA', firstName: 'JASON', position: 'DEPUTY SHERIFF', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 2719, lastName: 'PARROVECHIO', firstName: 'CHARLES', position: 'DEPUTY SHERIFF', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 7083, lastName: 'PETERSON', firstName: 'MARLIN', position: 'RVE DPTY- NO IMRF', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 7624, lastName: 'PETERSON', firstName: 'TERRENCE', position: 'RVE DPTY- NO IMRF', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 9119, lastName: 'PETRUS', firstName: 'JARED', position: 'CORRECTIONAL OFFICER', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 4864, lastName: 'PHILLIPS', firstName: 'BRENT', position: 'DEPUTY SHERIFF', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 5476, lastName: 'POBJECKY', firstName: 'FRANK', position: 'DEPUTY SHERIFF', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 1005, lastName: 'PONTE', firstName: 'ANTHONY', position: 'CORRECTIONS SERGEANT', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 6684, lastName: 'POSADA', firstName: 'MIGUEL', position: 'CORRECTIONAL OFFICER', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 9037, lastName: 'RAMIREZ', firstName: 'BRYAN', position: 'CORRECTIONAL OFFICER', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 8984, lastName: 'RAMIREZ-MIRANDA', firstName: 'ALEXANDER', position: 'CORRECTIONAL OFFICER', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 8473, lastName: 'RAWAILLOT', firstName: 'THERESA', position: 'RVE DPTY- NO IMRF', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 2087, lastName: 'RAXTER', firstName: 'RUTH', position: 'CORRECTIONAL OFFICER', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 7031, lastName: 'REDMOND', firstName: 'ROBERT', position: 'ADMINISTRATIVE ASSISTANT', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 8348, lastName: 'RICHARDSON', firstName: 'ANTONIO', position: 'CORRECTIONAL OFFICER', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 7256, lastName: 'RINALDI', firstName: 'ANDREW', position: 'DEPUTY SHERIFF', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 8423, lastName: 'RINCON', firstName: 'XAVIER', position: 'DEPUTY SHERIFF', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 5098, lastName: 'RIPORTELLA', firstName: 'DANIEL', position: 'SERGEANT', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 6576, lastName: 'RIVAS', firstName: 'ELIGIO', position: 'CORRECTIONAL OFFICER', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 8621, lastName: 'RIVERA', firstName: 'BENY', position: 'CORRECTIONAL OFFICER', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 8568, lastName: 'ROBERSON', firstName: 'MALIK', position: 'CORRECTIONAL OFFICER', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 2088, lastName: 'ROBERTS', firstName: 'NEIL', position: 'DEPUTY SHERIFF', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 3849, lastName: 'ROBERTS', firstName: 'JAKE', position: 'CORRECTIONAL OFFICER', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 5516, lastName: 'RODDEN', firstName: 'KENNETH', position: 'CORRECTIONAL OFFICER', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 9159, lastName: 'RODRIGUEZ', firstName: 'RYAN', position: 'CORRECTIONAL OFFICER', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 6757, lastName: 'ROSSI', firstName: 'JASON', position: 'DEPUTY SHERIFF', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 9078, lastName: 'ROWAN', firstName: 'TYLER', position: 'CORRECTIONAL OFFICER', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 1324, lastName: 'ROWE', firstName: 'ROBERT', position: 'CORRECTIONAL OFFICER', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 8629, lastName: 'RUSSELL', firstName: 'EVAN', position: 'DEPUTY SHERIFF', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 6758, lastName: 'RUTIAGA', firstName: 'MARIO', position: 'DEPUTY SHERIFF', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 5317, lastName: 'RYALL', firstName: 'SHAWN', position: 'DETECTIVE', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 2042, lastName: 'SALAZAR', firstName: 'DENISE', position: 'DEPUTY SHERIFF', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 8479, lastName: 'SAMMONS', firstName: 'SYDNEY', position: 'CORRECTIONAL OFFICER', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 4175, lastName: 'SAND', firstName: 'BRENDA', position: 'CORRECTIONAL OFFICER', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 4695, lastName: 'SATHRE', firstName: 'BRANDON', position: 'CORRECTIONAL OFFICER', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 4171, lastName: 'SAULTERS', firstName: 'CLARENCE', position: 'DEPUTY SHERIFF', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 3758, lastName: 'SAVAIANO', firstName: 'ANTHONY', position: 'DETECTIVE', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 8222, lastName: 'SAVINO', firstName: 'JACOB', position: 'DEPUTY SHERIFF', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 5168, lastName: 'SCHABACKER', firstName: 'MARK', position: 'CORRECTIONAL OFFICER', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 8806, lastName: 'SCHAEFER', firstName: 'KYLE', position: 'CORRECTIONAL OFFICER', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 2739, lastName: 'SCHEWE', firstName: 'JEFFREY', position: 'DEPUTY SHERIFF', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 7316, lastName: 'SCHUMAKER', firstName: 'STEPHEN', position: 'DEPUTY SHERIFF', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 2267, lastName: 'SCHWARZ', firstName: 'JONATHAN', position: 'CORRECTIONAL OFFICER', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 6204, lastName: 'SEAWORTH', firstName: 'TONYA', position: 'DEPUTY SHERIFF', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 4645, lastName: 'SEITZ', firstName: 'DAVID', position: 'DEPUTY SHERIFF', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 7317, lastName: 'SHANER', firstName: 'ANDREW', position: 'DEPUTY SHERIFF', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 8767, lastName: 'SHOEMAKER', firstName: 'HAYDEN', position: 'CORRECTIONAL OFFICER', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 4271, lastName: 'SMITH', firstName: 'JARED', position: 'CORRECTIONAL OFFICER', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 6901, lastName: 'SMITH', firstName: 'NICHOLAS', position: 'DEPUTY SHERIFF', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 5172, lastName: 'SMOOT', firstName: 'CRYSTAL', position: 'DETECTIVE', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 9077, lastName: 'SNYDER', firstName: 'MAX', position: 'CORRECTIONAL OFFICER', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 8823, lastName: 'SOCKWELL', firstName: 'CURTIS', position: 'RVE DPTY- NO IMRF', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 3203, lastName: 'SODERGREN', firstName: 'TIMI', position: 'CORRECTIONS SERGEANT', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 1864, lastName: 'SPEER', firstName: 'TIMOTHY', position: 'DEPUTY SHERIFF', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 1436, lastName: 'STACY', firstName: 'MATTHEW', position: 'CORRECTIONAL OFFICER', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 3684, lastName: 'STANLEY', firstName: 'TAMMIE', position: 'DEPUTY CHIEF', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 1086, lastName: 'STANSELL', firstName: 'GILBERT', position: 'RVE DPTY- NO IMRF', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 3889, lastName: 'STARKS', firstName: 'WILLIAM', position: 'CORRECTIONAL OFFICER', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 9160, lastName: 'STEIN', firstName: 'JAMES', position: 'CORRECTIONAL OFFICER', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 8886, lastName: 'STERNER', firstName: 'SAMANTHA', position: 'CORRECTIONAL OFFICER', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 6436, lastName: 'STEWART', firstName: 'EILEEN', position: 'CORRECTIONAL OFFICER', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 6685, lastName: 'STOLTZ', firstName: 'JONATHAN', position: 'DEPUTY SHERIFF', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 3603, lastName: 'STOVER', firstName: 'JASON', position: 'CORRECTIONAL OFFICER', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 8705, lastName: 'STREED', firstName: 'BRANDON', position: 'CORRECTIONAL OFFICER', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 6686, lastName: 'SUDDARTH', firstName: 'SAMUEL', position: 'DEPUTY SHERIFF', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 8474, lastName: 'SUJEWICZ', firstName: 'RAYMOND', position: 'RVE DPTY- NO IMRF', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 6186, lastName: 'SWANSON', firstName: 'JACKIE', position: 'CORRECTIONAL OFFICER', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 8272, lastName: 'SWISHER', firstName: 'JASON', position: 'CORRECTIONAL OFFICER', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 2995, lastName: 'TERRY', firstName: 'SHANE', position: 'CORRECTIONAL OFFICER', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 3954, lastName: 'TETZLAFF', firstName: 'ANGELA', position: 'DEPUTY SHERIFF', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 8349, lastName: 'TOKOTO', firstName: 'GAELLE', position: 'DEPUTY SHERIFF', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 7402, lastName: 'TURNER', firstName: 'SHELLY', position: 'DEPUTY SHERIFF', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 9001, lastName: 'UPLEGGER', firstName: 'MICHAEL', position: 'CORRECTIONAL OFFICER', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 8962, lastName: 'VALDIVIESO', firstName: 'BENJAMIN', position: 'DEPUTY SHERIFF', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 7267, lastName: 'VALENTINE', firstName: 'JEFFREY', position: 'CORRECTIONAL OFFICER', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 5514, lastName: 'VENTRE', firstName: 'FRANK', position: 'DEPUTY SHERIFF', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 8719, lastName: 'VERUCHI', firstName: 'ROBERT', position: 'RVE DPTY- NO IMRF', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 6182, lastName: 'VIOLA', firstName: 'JOSEPH', position: 'RVE DPTY- NO IMRF', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 1643, lastName: 'VOLDEN', firstName: 'TRUDY', position: 'CORRECTIONAL OFFICER', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 7557, lastName: 'VURNOVAS', firstName: 'AMANDA', position: 'CORRECTIONAL OFFICER', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 6203, lastName: 'WAGNER', firstName: 'LUCAS', position: 'SERGEANT', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 6662, lastName: 'WARMOTH', firstName: 'THOMAS', position: 'SERGEANT', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 6711, lastName: 'WASCO', firstName: 'JOSEPH', position: 'CORRECTIONAL OFFICER', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 2358, lastName: 'WATSON', firstName: 'ROBIN', position: 'CORRECTIONS SERGEANT', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 4436, lastName: 'WATSON', firstName: 'WILLIAM', position: 'RVE DPTY- NO IMRF', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 4322, lastName: 'WEBER', firstName: 'MICHAEL', position: 'LIEUTENANT', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 7046, lastName: 'WERMAGER', firstName: 'GARRET', position: 'CORRECTIONAL OFFICER', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 8813, lastName: 'WIEGMAN', firstName: 'HAYDEN', position: 'CORRECTIONAL OFFICER', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 6767, lastName: 'WILCOX', firstName: 'DUSTIN', position: 'CORRECTIONAL OFFICER', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 4538, lastName: 'WILLIAMS', firstName: 'JENNY', position: 'CORRECTIONAL OFFICER', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 8926, lastName: 'WILLIS', firstName: 'TIMOTHY', position: 'CORRECTIONAL OFFICER', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 8749, lastName: 'WILSON', firstName: 'MICHAEL', position: 'CORRECTIONAL OFFICER', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 3725, lastName: 'WITT', firstName: 'DAVID', position: 'DETECTIVE', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 1869, lastName: 'WOODFORD', firstName: 'BRIAN', position: 'DEPUTY SHERIFF', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 3847, lastName: 'WRASSE', firstName: 'CASEY', position: 'CORRECTIONAL OFFICER', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 7068, lastName: 'WRIGHT', firstName: 'STEPHEN', position: 'DEPUTY SHERIFF', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 5515, lastName: 'WROBLE', firstName: 'GILBERT', position: 'CORRECTIONAL OFFICER', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 5192, lastName: 'WYERS', firstName: 'DONALD', position: 'CORRECTIONAL OFFICER', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 3395, lastName: 'WYSOCKI', firstName: 'TOMASZ', position: 'DEPUTY SHERIFF', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 4355, lastName: 'YOUNG', firstName: 'JOSEPH', position: 'CORRECTIONAL OFFICER', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 6759, lastName: 'ZELLMAN', firstName: 'TROY', position: 'DEPUTY SHERIFF', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 7410, lastName: 'ZIOLKOWSKI', firstName: 'JEFFREY', position: 'RVE DPTY- NO IMRF', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
                {starNumber: 8622, lastName: 'ZIRIAXE', firstName: 'AUSTIN', position: 'CORRECTIONAL OFFICER', startDate: '1/1/2000', status: 'ACTIVE DUTY'},
            ],
            tempStaff: {
                starNumber: '',
                lastName: '',
                firstName: '',
                position: '',
                startDate: '',
                status: '',
            },
            addProfileModal: false,
            editProfileModal: false,
            deleteProfileModal: false,
            positions: [
                'ADMINISTRATIVE ASSISTANT',
                'CHIEF DEPUTY',
                'CORRECTIONAL OFFICER',
                'CORRECTIONS CAPTAIN',
                'CORRECTIONS LIEUTENANT',
                'CORRECTIONS SERGEANT',
                'COUNTY SHERIFF',
                'DEPUTY CHIEF',
                'DEPUTY SHERIFF',
                'DETECTIVE',
                'EVIDENCE OFFICER',
                'EXEC ASSISTANT TO THE SHERIFF',
                'LIEUTENANT',
                'PT WITH BENEFITS',
                'RVE DPTY- NO IMRF',
                'SERGEANT',
            ],
            columns: ['lastName', 'firstName', 'position', 'starNumber', 'startDate', 'status'],
            tOptions: {
                orderBy: {column: 'name', ascending: true},
                headings: {
                    status: 'Status',
                    position: 'Position',
                    firstName: 'First Name',
                    lastName: 'Last Name',
                    startDate: 'Start Date',
                    starNumber: 'Badge Number',
                    edit: 'Edit',
                },
                sortIcon: {base: 'fas', up: 'fa-sort-up', down: 'fa-sort-down', is: 'fa-sort'},
                pagination: {
                    chunk: 5,
                    edge: false,
                    nav: 'scroll'
                },
                perPage: 25,
                skin: 'table table-striped table-hover',
            },
            theme: 'bootstrap4',
        }
    },
    methods: {
        editProfile(profile) {
            this.tempStaff = Object.assign({}, profile);
            let tempDate = new Date(this.tempStaff.startDate);
            if(tempDate.getMonth() < 10 && tempDate.getDate() < 10) {
                this.tempStaff.startDate = tempDate.getFullYear() + '-0' + (tempDate.getMonth() + 1) + '-0' + tempDate.getDate();
            } else if(tempDate.getMonth() < 10 && tempDate.getDate() >= 10) {
                this.tempStaff.startDate = tempDate.getFullYear() + '-0' + (tempDate.getMonth() + 1) + '-' + tempDate.getDate();
            } else if(tempDate.getMonth() >= 10 && tempDate.getDate() < 10) {
                this.tempStaff.startDate = tempDate.getFullYear() + '-' + (tempDate.getMonth() + 1) + '-0' + tempDate.getDate();
            } else {
                this.tempStaff.startDate = tempDate.getFullYear() + '-' + (tempDate.getMonth() + 1) + '-' + tempDate.getDate();
            }
            this.editProfileModal = true;
        },
        clearProfileFields() {
            this.tempStaff = Object.assign({} , {starNumber: '', lastName: '', firstName: '', position: '', startDate: '', status: ''})
        },
        postProfile() {
            this.addProfileModal = false;
            let offset = new Date(this.tempStaff.startDate).getTimezoneOffset() * 60000;
            let unixDate = new Date(this.tempStaff.startDate).getTime();
            unixDate += offset
            let timeDate = new Date(unixDate);
            this.tempStaff.startDate = timeDate.toLocaleDateString()
            this.jailPostProfile(this.tempStaff)
        },
        putProfile() {
            this.editProfileModal = false;
            let offset = new Date(this.tempStaff.startDate).getTimezoneOffset() * 60000;
            let unixDate = new Date(this.tempStaff.startDate).getTime();
            unixDate += offset
            let timeDate = new Date(unixDate);
            this.tempStaff.startDate = timeDate.toLocaleDateString()
            this.jailPutProfile(this.tempStaff)
        },
        jailPostProfile(profile) {
            this.staff.push(profile)
        },
        jailPutProfile(newProfile) {
            let index = this.staff.findIndex(oldProfile => {
                return oldProfile.starNumber === newProfile.starNumber
            })
            this.staff[index] = Object.assign({}, newProfile)
        },
        jailDeleteProfile(deleteProfile) {
            let index = this.staff.findIndex(profile => {
                return profile.starNumber === deleteProfile.starNumber
            })
            this.staff.splice(index, 1);
        },
        fillForm() {
            return (this.tempStaff.status === '' || this.tempStaff.firstName === '' || this.tempStaff.lastName === '' ||
                   this.tempStaff.starNumber === '' || this.tempStaff.position === '' || this.tempStaff.startDate === '')
        },
        formatBadge(e){
            return String(e).substring(0,4);
        },
        openDeleteModal() {
            this.deleteProfileModal = true;
        },
        deleteProfile() {
            this.deleteProfileModal = false;
            this.editProfileModal = false;
            this.jailDeleteProfile(this.tempStaff)
        },
    },
}
/*Makes B-Form-Input All Caps*/
Vue.directive('uppercase', {
    update(el) {
        el.value = el.value.toUpperCase()
    },
})
</script>

<style scoped>
</style>